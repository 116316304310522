import React from 'react'
import { CircularProgress, Backdrop } from '@mui/material';

const Preloads = () => {
    return (
        <>
            <Backdrop open={true} style={{ color: '#fff', zIndex: 1300 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Preloads