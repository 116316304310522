import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GiSelfLove } from "react-icons/gi";
import Preloads from '../Preloads';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';

const DogsforAdoptions = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dogsForAdoptions, setDogsForAdoptions] = useState([]);
    const [error, setError] = useState(null);

    // Pagination states
    const [pages, setPages] = useState(1); // Current page
    const [total, setTotal] = useState(0); // Total number of dogs
    const [petsPerPage, setPetsPerPage] = useState(10); // Number of dogs per page

    const scrollToTop = () => {
        window.scrollTo(0, 0);  // Scroll to top when link is clicked
    };

    useEffect(() => {
        const getDogsForAdoptions = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/allpets/`);
                setDogsForAdoptions(res.data);
                setTotal(res.data.length); // Set total number of dogs
            } catch (error) {
                console.error('Something went wrong: ', error);
                setError('Could not load data. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        getDogsForAdoptions();
    }, []);

    // Pagination calculations
    const indexOfLastPet = pages * petsPerPage; // Index of the last pet on the current page
    const indexOfFirstPet = indexOfLastPet - petsPerPage; // Index of the first pet on the current page
    const currentPets = dogsForAdoptions.slice(indexOfFirstPet, indexOfLastPet); // Get the pets for the current page

    // Function to handle page change
    const handlePageChange = (newPage) => {
        setPages(newPage);
        scrollToTop(); // Scroll to top when page changes
    };

    return (
        <div className='flex flex-col justify-center items-center mx-auto mt-16 py-16 p-3'>
            <div className='bg-green-50'>
                <div>
                    <p className='py-4 mb-10 text-center'>
                        <div className='text-center text-2xl py-4 font-semibold'>Dogs for Adoption</div>
                        <div className='text-justify'>
                            Destiny shelter is dedicated to providing dogs and puppies with a second chance to find loving and safe homes. On this page, we have dogs for adoption and puppies for adoption. Dogs and puppies currently available for adoption are featured below. New animals are added as they become available. You can help! Please share their profiles, because every dog for adoption makes room for another deserving pet.
                        </div>
                    </p>
                </div>
                {isLoading ? (
                    <Preloads />
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <>
                        <div className='grid md:grid-cols-5 md:gap-x-3 gap-y-4 md:px-3'>
                            {currentPets.map((dog) => (
                                <Link to={`/details/${dog.slug}`} onClick={scrollToTop} key={dog.id}>
                                    <div className='border border-3 shadow-lg rounded-lg group hover:scale-105 transition-all duration-300'>
                                        <img src={dog.images} alt={`Image of ${dog.petname}`} className='h-full md:h-[260px] w-full rounded-md object-cover' />
                                        <div className='relative'>
                                            <p className='absolute bg-white w-full inset-0 hidden group-hover:flex bottom-0 justify-center items-center'>
                                                <span className='flex items-center inset-0 text-[#70a13e]'><GiSelfLove size={40} />Happy Home</span>
                                            </p>
                                            <p className='text-center font-semibold text-gray-700 text-2xl py-10'>{dog.petname}</p>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className='flex py-6 justify-center'>
                            <Pagination
                                onChange={handlePageChange} // Use handlePageChange directly
                                pageSize={petsPerPage}
                                total={total}
                                current={pages}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DogsforAdoptions;
