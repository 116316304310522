import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Navlinks = ({ closeNavbar }) => {
    const handleScrollToTop = () => {
        window.scrollTo(0, 0);  // Scroll to top when link is clicked
    };
    const Links = [
        {
            name: "About", submenu: true, sublinks: [
                {
                    Head: "About",
                    sublink: [
                        {
                            name: 'History', link: '/history', id: 1,
                        },
                        {
                            name: 'Our Services', link: '/service', id: 2,
                        },
                        {
                            name: 'Faq', link: '/fq', id: 3,
                        },
                        {
                            name: 'Bord&Staff', link: '/boardstaff', id: 4,
                        },
                    ]
                },
            ]
        },
        {
            name: "Adopt", submenu: true, sublinks: [
                {
                    Head: "About",
                    sublink: [
                        {
                            name: 'Dog Application', link: '/dogapplication', id: 5,
                        },
                        {
                            name: 'Ready to Adopt?', link: '/readytoadopt', id: 6,
                        },
                        {
                            name: 'Adoptation Information', link: '/adoptioninfo', id: 7,
                        },
                        {
                            name: 'Adoptation Discount', link: '/discount', id: 8,
                        },
                    ]
                },
            ]
        },
        {
            name: "Large Breeds", submenu: true, sublinks: [
                {
                    Head: "About",
                    sublink: [
                        {
                            name: 'German Shepherd', link: '/germanshepherd', id: 9,
                        },
                        {
                            name: 'Dobermann Puppies', link: '/dobermann', id: 10,
                        },
                        {
                            name: 'Alaskan Malamute', link: '/alaskan', id: 11,
                        },
                        {
                            name: 'Airedale Terrier', link: '/airedale', id: 12,
                        },
                    ]
                },
            ]
        },
        {
            name: "Small Breeds", submenu: true, sublinks: [
                {
                    Head: "About",
                    sublink: [
                        {
                            name: 'Maltese puppies', link: '/maltese', id: 13,
                        },
                        {
                            name: 'Chihuahua puppies', link: '/chihuahua', id: 14,
                        },
                        {
                            name: 'Shih Tzu puppies', link: '/shihtzu', id: 15,
                        },
                        {
                            name: "Pug puppie's", link: '/pug', id: 16,
                        },
                        {
                            name: 'Toy poodle puppies', link: '/toypoodle', id: 17,
                        },
                    ]
                },
            ]
        },
        {
            name: "Categories", submenu: true, sublinks: [
                {
                    Head: "About",
                    sublink: [
                        {
                            name: 'Emotional Sopport dogs', link: '/emotional', id: 18,
                        },
                        {
                            name: 'Our Apartments dogs', link: '/apartmentsdogs', id: 19,
                        },
                        {
                            name: 'Farmily Dogs', link: '/family', id: 20,
                        },
                        {
                            name: "Small Dogs", link: '/smaill_dogs', id: 21,
                        },
                        {
                            name: 'Hypoallergenic dogs', link: '/hypoallergenic', id: 22,
                        },
                        {
                            name: 'Medium Breed', link: '/midum_breed', id: 23,
                        },
                        {
                            name: 'Large Breed', link: '/large_breed', id: 24,
                        },
                    ]
                },
            ]
        },
        {
            name: "Toy Breeds", submenu: true, sublinks: [
                {
                    Head: "About",
                    sublink: [
                        {
                            name: 'Yorkshire', link: '/yorkshire', id: 24,
                        },
                        {
                            name: "Chihuahua's", link: '/chihuahuas', id: 25,
                        },
                        {
                            name: "Poodle's", link: '/poodle', id: 26,
                        },
                        {
                            name: "Maltese's", link: '/maltese', id: 27,
                        },
                        {
                            name: "Pomeranian's", link: '/pomeranian', id: 28,
                        },

                    ]
                },
            ]
        },
        {
            name: "Contact Us", submenu: true, sublinks: [
                {
                    Head: "About",
                    sublink: [
                        {
                            name: 'Contact Us', link: '/contact', id: 29,
                        },


                    ]
                },
            ]
        },


    ]
    const [header, setHeader] = useState("")

    // const [navopen, setNavOpen] = useState(false)
    //toggle submenu
    const toggleSubmenu = (name) => {
        setHeader(setHeader === name ? "" : name);
    };

    const handleCloseBar = () => {
        setHeader(""); // Close any open submenu
        closeNavbar(); // Close the navbar
        handleScrollToTop(); // Scroll to the top
    };
    return (
        <>
            {Links.map((data) => (

                <div >
                    <div className='px-3 md:px-1 text-left md:cursor-pointer group'>
                        <p className='py-4 flex justify-between items-center md:pr-0 pr-5 group cursor-pointer' onClick={() => toggleSubmenu(data.name)} aria-expanded={Headers === data.name}>
                            {data.name}
                            <span className='md:hidden text-xl md:mt-1 md:ml-3 inline'>
                                {header === data.name ? <IoIosArrowDown /> : <IoIosArrowUp />}
                            </span>
                            <span className='hidden md:inline text-xl md:mt-1 md:ml-2 group-hover:rotate-180'>
                                <IoIosArrowDown />
                            </span>
                        </p>
                        {data.submenu && header === data.name && ( // Show submenu only if it's active
                            <div className=''>
                                <div className=' absolute top-10 hidden group-hover:md:block hover:md:block'>
                                    <div className='py-7'>
                                        <div className='w-4 h-4 left-3 absolute mt-1 bg-gray-100 shadow-lg rotate-45'>

                                        </div>
                                    </div>
                                    <div className='bg-white shadow-xl p-3.5 w-[200px]'>
                                        {data.sublinks.map((mydatalink, index) => (
                                            <div key={index}>
                                                {mydatalink.sublink.map((allmydata, index) => (
                                                    <li key={index} className='text-sm my-4 text-gray-600 cursor-default'
                                                        onClick={() => {
                                                            handleCloseBar();
                                                            closeNavbar();
                                                            handleScrollToTop();
                                                        }}>
                                                        <Link to={allmydata.link}>
                                                            {allmydata.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* mobile */}
                    <div className={`${header === data.name ? 'md:hidden' : 'hidden'}`}>
                        {data.sublinks?.map((links, index) => (
                            <div key={index}>
                                {links.sublink.map((listLink, index) => (
                                    <li key={index} className='py-4 pl-14' onClick={() => { closeNavbar(); handleScrollToTop() }}>
                                        <Link to={listLink.link}>{listLink.name}</Link>
                                    </li>
                                ))}
                            </div>
                        ))}
                    </div>

                </div>
            ))}
        </>
    )
}

export default Navlinks
