import React, { useState } from 'react'
import Buttons from '../buttons/Buttons'
import GermanShepherd from '../larg_breed/GermanShepherd'
import DobermannPuppies from '../larg_breed/DobermannPuppies'
import AlaskanMalamute from '../larg_breed/AlaskanMalamute'
import AiredaleTerrier from '../larg_breed/AiredaleTerrier'

const Large_breeds = () => {
    const button = [
        "German Shepherd", "Doberman Puppies", "Alaskan Malamute", "Airedale Terrier"
    ]
    const RenderComponent = ({ index }) => {
        switch (index) {
            case 0:
                return <GermanShepherd />
                break;
            case 1:
                return <DobermannPuppies />
                break;
            case 2:
                return <AlaskanMalamute />
                break;
            case 3:
                return <AiredaleTerrier />
                break;
            default:
                break;
        }
    }
    const [isSelected, setIsSelected] = useState(0)
  return (
      <div className='py-16'>
          <div className='bg-gray-100'>
              <p className='py-4 text-xl md:text-3xl font-semibold text-center mt-6 '>
                Large breed Dogs for Adoption
              </p>
              <p className='py-3 text-justify px-4'>
                  Large breed dogs make versatile companions. Large breed puppies keep you motivated and act like watch dogs, their  size alone can deter potential intruders . They also act as great exercise partners for active people as they can keep you hike and jogging
              </p>
          </div>

          <div className=' p-3 gap-y-4 bg-[#70a13e] mt-5'>
              <div className=' text-white font-semibold text-2xl mt-4 border-black uppercase'>Large breed</div>
          </div>
          <div className='flex-1 md:flex gap-x-5'>
              <div className=' mt-3 bg-orange-100 md:flex-col md:justify-start text-gray-500'>
                  <div className=' grid-cols-4 gap-x-3 gap-y-2 w-full md:w-[260px] px-4'>
                      <Buttons button={button} isSelected={isSelected} setIsSelected={setIsSelected} />
                  </div>
              </div>
              <div className='mt-5'>
                  <RenderComponent index={isSelected} />
              </div>
          </div>

      </div>
  )
}

export default Large_breeds
