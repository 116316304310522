import React from 'react'
import contact from '../images/woman-kissing-dog-on-head.webp'
import { Link } from 'react-router-dom'
const Gools = () => {
    const handleScrollToTop = () => {
        window.scrollTo(0, 0);  // Scroll to top when link is clicked
    };
    return (
        <div>
            <div className='flex justify-center mx-auto w-full border border-3'>
                <div>
                    <p className='text-2xl font-semibold text-center py-5'>Our Goals</p>
                    <div className='grid md:grid-cols-2 justify-center mx-auto px-4 gap-x-4 gap-y-5'>
                        <div className=''>
                            <div className=' text-justify'>
                                Destiny shelter is an Australian Organization dedicated to rescuing and rehoming puppies and dogs. Our mission is to find loving and responsible families for this animals and ensuring they receive the care and attention they deserve. Destiny shelter carefully select homes and provide a new start for pets in needs. Our efforts help reduce the numbers of stray and abandoned dogs making a positive impact on both the pets and families that adopt them.
                            </div>
                        </div>
                        <div>
                            <img src={contact} alt="contact" className='w-full rounded-md' />
                        </div>
                    </div>
                    <p className='text-center py-5 mt-4 hover:scale-105 transition-all duration-300 '>
                        <Link onClick={handleScrollToTop} to="/enquiries" className='p-2 py-3 px-6 bg-[#70a13e] text-white rounded-md hover:bg-black transition-all duration-300 ease-in-out font-semibold'>Contact Us</Link>
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Gools