import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { message as antdMessages } from 'antd';
import { Spin } from 'antd'; // Import Spin from Ant Design for a loading indicator

const Enquiries = () => {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        address: '',
        mobile: '',
        subject: '',
        location: '',
        categoryRequest: '',
        petIntres: '',
        messagedata: ''
    });

    const [loading, setLoading] = useState(false); // Add loading state

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when starting the request
        try {
            const getapi = await axios.post(`${process.env.REACT_APP_API_URL}/api/enquring/`, form);
            antdMessages.success('Your enquiry has been submitted successfully.');
            setForm({
                firstName: '',
                lastName: '',
                address: '',
                mobile: '',
                subject: '',
                location: '',
                categoryRequest: '',
                petIntres: '',
                messagedata: ''
            });
        } catch (error) {
            console.log(error);
            antdMessages.error('There was an error submitting your enquiry. Please try again.');
        } finally {
            setLoading(false); // Reset loading to false after the request completes
        }
    };

    return (
        <div className='bg-gray-100 w-full'>
            <div className='px-10 pt-10'>
                <p className='text-xl mt-5'>Contact</p>
                <div className='mt-4'>
                    <p className='text-2xl md:text-5xl font-semibold text-orange-400'>Have questions ?</p>
                    <p className='py-6 text-gray-500 text-justify'>
                        If you have a question on how to adopt or a pet ad you saw on the site, you may find the answer you're looking for by contacting us on the email below or summiting a request and our Team will get back to you within 30 minutes.
                    </p>
                </div>
            </div>
            <div className='flex-1 md:flex justify-around max-w-full container px-3'>
                <div>
                    <p className='py-4 text-3xl text-gray-600'>Adoption</p>
                    <p className='text-xl border-b-2 mb-3 text-orange-400'>info@Destinyshelter.com</p>
                    <div>
                        <p className='py-4 text-3xl text-gray-600'>Phone</p>
                        <p className='text-xl border-b-2 mb-3 text-orange-400'>Phone: +3827837384</p>
                    </div>
                </div>
                <div className='md:border-l-2 md:border-gray-700 pl-6'>
                    <form onSubmit={handleSubmit}>
                        <div className='flex items-center gap-x-4'>
                            <div>
                                <input type="text" onChange={handleInputChange} value={form.firstName} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2' name='firstName' placeholder='First Name' />
                            </div>
                            <div>
                                <input type="text" onChange={handleInputChange} value={form.lastName} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2' name='lastName' placeholder='Last Name' />
                            </div>
                        </div>
                        <div>
                            <input type="text" onChange={handleInputChange} name="address" value={form.address} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2' placeholder='Address' />
                        </div>
                        <div>
                            <input type="text" onChange={handleInputChange} name="mobile" value={form.mobile} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2' placeholder='Mobile Number' />
                        </div>
                        <div>
                            <input type="text" onChange={handleInputChange} name="subject" value={form.subject} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2' placeholder='Subject' />
                        </div>
                        <div>
                            <input type="text" onChange={handleInputChange} name="location" value={form.location} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2' placeholder='Location' />
                        </div>
                        <div>
                            <select name="categoryRequest" onChange={handleInputChange} value={form.categoryRequest} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2'>
                                <option value="">--Request Category---</option>
                                <option value="Adoption Inquiry">Adoption Inquiry</option>
                                <option value="Adoption Follow-Up">Adoption Follow-Up</option>
                                <option value="Donation Information">Donation Information</option>
                            </select>
                        </div>
                        <div>
                            <input type="text" onChange={handleInputChange} value={form.petIntres} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2' name="petIntres" placeholder='Name of Interested Pet' />
                        </div>
                        <div>
                            <textarea name="messagedata" onChange={handleInputChange} value={form.messagedata} className='w-full p-2 py-3 pl-2 border border-gray-400 rounded-md pt-2 mt-2' placeholder='Your Message'></textarea>
                        </div>
                        <div>
                            <button className='bg-orange-500 p-2 px-6 text-xl rounded-md text-white mt-3' disabled={loading}>
                                {loading ? <Spin /> : 'Send'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Enquiries;
