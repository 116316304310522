import React from 'react'
import { Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa"; const Doogs = () => {
    return (
        <div className='flex justify-center items-center py-6'>
            <Link to="/dogsForAdoption" className=''>
                <p className='bg-[#70a13e] text-gray-200 p-2 rounded-lg px-8 text-2xl font-normal hover:bg-gray-600 flex items-center'>Dogs for adption&nbsp;<FaArrowRight className='text-xl' /></p>
            </Link>
        </div>
    )
}

export default Doogs